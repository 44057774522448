import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// component
import { SortByIndex } from './sortByIndex.pipe';
import { SafePipe } from './safe.pipe';


@NgModule({
  declarations: [
	SortByIndex,
    SafePipe
  ],
  imports: [
	CommonModule
  ],
  exports: [
	SortByIndex,
    SafePipe
  ]
})
export class SharedModule { }
