import { Observable, of, throwError } from "rxjs";
import { Injectable, Inject, Optional, InjectionToken } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { switchMap, tap } from "rxjs/operators";
import { EnvironmentService } from "@environments/environment.service";
import { isNullOrUndefined } from "@app/core/utils/isNullOrUndefined";

export const API_BASE_URL = new InjectionToken<string>("API_BASE_URL");

const STATUS_OK_CODES = [200, 201, 204];

@Injectable({
  providedIn: "root",
})
export class CufiAdminApi {
  private http: HttpClient;
  private baseUrl = "";

  constructor(
    @Inject(HttpClient) http: HttpClient,
    private envService: EnvironmentService,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string,
  ) {
    this.http = http;

    // Get the API URL
    this.envService.environment
      .pipe(tap((env) => (this.baseUrl = env.apiUrl)))
      .subscribe();
  }

  public GetApiCall<ReturnType>(
    apiEndPoint: string,
    expectedResponseCode: number,
    path: any,
    query: any,
  ): Observable<ReturnType> {
    return this.handleRequest(
      this.http.get<ReturnType>(this.buildUrl(apiEndPoint, path, query), {
        observe: "response",
        headers: {
          Accept: "application/json",
        },
      }),
      expectedResponseCode,
    );
  }

  public GetFileApiCall(
    apiEndPoint: string,
    expectedResponseCode: number,
    path: any,
    query: any,
  ): Observable<File> {
    // @ts-ignore
    return this.handleRequest(
      this.http.get(this.buildUrl(apiEndPoint, path, query), {
        observe: "response",
        responseType: "arraybuffer",
      }),
      expectedResponseCode,
    ) as Observable<File>;
  }

  public PostApiCall<ReturnType, BodyType>(
    apiEndPoint: string,
    expectedResponseCode: number,
    path: any,
    query: any,
    body: BodyType,
  ): Observable<ReturnType> {
    return this.handleRequest(
      this.http.post<ReturnType>(
        this.buildUrl(apiEndPoint, path, query),
        body,
        {
          observe: "response",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      ),
      expectedResponseCode,
    );
  }

  public PutApiCall<ReturnType, BodyType>(
    apiEndPoint: string,
    expectedResponseCode: number,
    path: any,
    query: any,
    body: BodyType,
  ): Observable<ReturnType> {
    return this.handleRequest(
      this.http.put<ReturnType>(this.buildUrl(apiEndPoint, path, query), body, {
        observe: "response",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      expectedResponseCode,
    );
  }

  public DeleteApiCall(
    apiEndPoint: string,
    expectedResponseCode: number,
    path: any,
    query: any,
  ): Observable<void> {
    return this.handleRequest(
      this.http.delete<void>(this.buildUrl(apiEndPoint, path, query), {
        observe: "response",
        headers: {
          Accept: "application/json",
        },
      }),
      expectedResponseCode,
    );
  }

  public DeleteApiCallWithReturnType<ReturnType>(
    apiEndPoint: string,
    expectedResponseCode: number,
    path: any,
    query: any,
  ): Observable<ReturnType> {
    return this.handleRequest(
      this.http.delete<ReturnType>(this.buildUrl(apiEndPoint, path, query), {
        observe: "response",
        headers: {
          Accept: "application/json",
        },
      }),
      expectedResponseCode,
    );
  }

  // Parse any path tokens that need replaced for this api call
  private parseUrl(apiEndPoint: string, path: any): string {
    // Force the URL to lowercase
    let url = (this.baseUrl + apiEndPoint).toLocaleLowerCase();

    for (const key in path) {
      if (!!path[key]) {
        url = url.replace(`{${key}}`.toLocaleLowerCase(), path[key]);
      } else {
        throw new Error(`Missing path information for: ${key}`);
      }
    }

    return url;
  }

  private isValueComplexObject(value: any) {
    return value !== null && typeof value === "object" && !Array.isArray(value);
  }

  // Parse and build any query string parameters need for this api call
  private parseQueryString(currentUrl: string, query: object): string {
    if (isNullOrUndefined(query) || Object.keys(query).length === 0)
      return currentUrl;

    const flatKeyValuePairs = Object.entries(query).reduce(
      (acc, [key, value]) => {
        if (this.isValueComplexObject(value)) {
          // Loop through each nested key and add as a nested key ('foo.bar').
          Object.entries(value).forEach(([nestedKey, nestedValue]) => {
            if (this.isValueComplexObject(nestedValue)) {
              // Loop through one more nested depth if necessary ('foo.bar.baz')
              Object.entries(nestedValue as any).forEach(
                ([nestedKey2, nestedValue2]) => {
                  acc.push([`${key}.${nestedKey}.${nestedKey2}`, nestedValue2]);
                },
              );
            } else {
              acc.push([`${key}.${nestedKey}`, nestedValue]);
            }
          });
        } else {
          acc.push([key, value]);
        }

        return acc;
      },
      [] as [string, any][],
    );

    const queryParams = flatKeyValuePairs.reduce((acc, [key, value]) => {
      const addToAcc = (valueToAdd: any) => {
        if (valueToAdd === undefined || value === null || valueToAdd === "")
          return;
        acc.push(`${key}=${encodeURIComponent(valueToAdd)}`);
      };

      if (Array.isArray(value)) {
        value.forEach(addToAcc);
      } else {
        addToAcc(value);
      }

      return acc;
    }, [] as string[]);

    return `${currentUrl}?${queryParams.join("&")}`;
  }

  // Convenience method to build the full url
  private buildUrl(apiEndPoint: string, path: any, query: any): string {
    let url = this.parseUrl(apiEndPoint, path);
    url = this.parseQueryString(url, query);
    return url;
  }

  // We would like to ensure proper status codes.  We would also like to report the error as a side effect.
  private handleRequest<ReturnType>(
    request$: Observable<HttpResponse<ReturnType>>,
    expectedStatus: number,
  ): Observable<ReturnType> {
    return request$.pipe(
      switchMap((response) => {
        const isStatusOk =
          response.status === expectedStatus ||
          STATUS_OK_CODES.indexOf(response.status) > -1;

        if (response.ok && isStatusOk) return of(response.body as ReturnType);

        // This error means that the status was not what we expected
        return throwError(
          new HttpErrorResponse({
            error: response.body,
            status: response.status,
            statusText: "Unexpected error in response",
            url: response.url ?? undefined,
            headers: response.headers,
          }),
        );
      }),
    );
  }
}
