import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormsApiService } from '@app/core/api/services/forms-api.service';
import { LoadingService } from '@app/core/services/loading.service';
import { Router, ActivatedRoute, UrlSerializer } from '@angular/router';
import { Location } from '@angular/common';
import { FormField } from "@app/core/api/models/FormField";
import { ContentField } from "@app/core/api/models/ContentField";
import { ContactField } from "@app/core/api/models/ContactField";
import { ContactType } from "@app/core/api/models/ContactType";
import { RadioField } from "@app/core/api/models/RadioField";
import { CheckboxField } from "@app/core/api/models/CheckboxField";
import { DonationField } from "@app/core/api/models/DonationField";
import { TextField } from "@app/core/api/models/TextField";
import { NumberField } from "@app/core/api/models/NumberField";
import { SharedModule } from '../../shared/shared.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SEOService } from '@app/shared/seo.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { YouTubePlayer } from '@angular/youtube-player';
import Editor from 'ckeditor5/build/ckeditor';
import * as _ from 'lodash';
import * as Handlebars from "handlebars";
import * as moment from 'moment';
import {
  UntypedFormBuilder,
  Validators,
  ValidatorFn,
  ValidationErrors,
  UntypedFormArray,
  FormArray,
  FormControl,
  FormGroup,
  AbstractControl,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';




const iframeCSS: string = `
  br {
    display: none;
  }
  label {
    font-weight: 400;
    margin: 0;
    color: #222;
    font-family: monospace;
    font-size: 13px;
    display: block;
    padding: 1em 0 0.5em;
  }
  label:first-child {
    padding-top: 0.5em;
  }
  input,
  select {
    display: block;
    padding: 0.25em 0.5em;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #B9BFC5;
    border-radius: 0.2em;
  }
  input:focus,
  select:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  select {
    display: inline-block;
    width: 80px;
  }
  input {
    width: 90%;
  }
`;



@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
}) export class FormComponent {
  title = 'cufi.forms.ui';
  adminPreview: boolean = false;
  formLoading: boolean = true;
  idString: string;
  vanityString!: string;
  formId!: number;
  Editor = Editor;
  showCustom = false;
  showValidation: boolean = false;
  submissionComplete: boolean = false;
  donationIndex?: number;
  contactIndex?: number;
  donationHidden?: boolean = false;
  contactHidden?: boolean = false;
  formNotFound: boolean = false;
  validToken: boolean = false;
  tokenQueried: boolean = false;
  customDonationAmount: number = 0;
  showDonationType: number = 1;
  queryParams: any = {};
  dayOptions: number[] = [...Array(28).keys()];
  currentYear: number = new Date().getFullYear();
  submissionData: any = {};
  standardEditorConfig = {toolbar: [
    'bold',
    'italic',
    '|',
    'numberedList',
    'bulletedList',
    '|',
    'link'
  ]};

  iframeCCSrc: string = 'https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html' +
    '?css=' + encodeURIComponent(iframeCSS) +
    '&useexpiry=true&usecvv=true';
  iframeACHSrc: string = 'https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html' +
    '?css=' + encodeURIComponent(iframeCSS) +
    '&fullmobilekeyboard=true';


  donationValidation: ValidatorFn = (donationGroup: AbstractControl): ValidationErrors | null => {
    // No donation, no validation
    if (donationGroup.value.type === 0) {
      return null;
    }

    const val = donationGroup.value.value;

    // Return Error if
    if (
      val.amount === null ||
      (val.amount === -1 && this.customDonationAmount <= 0)
    ) {
      return { missingDonationInfo: true };
    }

    if (val.paymentType === 'cc') {
      if (
        !val.account ||
        !val.name ||
        !val.verificationCode ||
        !val.postalCode
      ) {
        return { missingCCInfo: true };
      }
    }

    if (val.paymentType === 'ach') {
      if (
        !val.account ||
        !val.name ||
        !val.routing ||
        !val.accountType
      ) {
        return { missingCCInfo: true };
      }
    }

    // All good
    return null;
  }


  updateContactRequirements(submissionGroup: FormGroup, adminObj: any) {
    const isOrg = submissionGroup.value.isOrg;

    // Start by removing all requirements
    for (let control in submissionGroup.controls) {
      submissionGroup.get(control)?.removeValidators(Validators.required);
      submissionGroup.get(control)?.updateValueAndValidity();
    }

    // Bail if copying another address
    if (submissionGroup.value.copyPrimary || submissionGroup.value.copyBilling) return;

    // Otherwise loop through fields
    for (let key in adminObj.fields) {
      if (adminObj.fields[key].required && adminObj.fields[key].display) {
        switch (key) {
          case 'name':
            if (isOrg) {
              submissionGroup.get('orgName')?.addValidators(Validators.required);
              submissionGroup.get('orgName')?.updateValueAndValidity();
            } else {
              submissionGroup.get('firstName')?.addValidators(Validators.required);
              submissionGroup.get('firstName')?.updateValueAndValidity();
              submissionGroup.get('lastName')?.addValidators(Validators.required);
              submissionGroup.get('lastName')?.updateValueAndValidity();
            }
            break;
          case 'birthday':
          case 'gender':
            if (!isOrg) {
              submissionGroup.get(key)?.addValidators(Validators.required);
              submissionGroup.get(key)?.updateValueAndValidity();
            }
            break;
          default:
            submissionGroup.get(key)?.addValidators(Validators.required);
            submissionGroup.get(key)?.updateValueAndValidity();
            break;
        }
      }
    }
  }


  // Admin Form
  adminForm = this.formBuilder.group({
    id: [undefined],
    startDate: [null],
    endDate: [null],
    name: ['', Validators.required],
    active: [false, Validators.required],
    errorMessage: [''],
    successMessage: [''],
    confirmationEmailMessage: [''],
    formFields: this.formBuilder.array([]),
    modifiedBy: [''],
    modifiedOn: [''],
    metaTitle: [''],
    metaDescription: [''],
    metaImage: [''],
    revisionId: [''],
    slug: [''],
    submitText: ['']
  });


  // Submission Form
  submissionForm = this.formBuilder.group({
    fields: this.formBuilder.array([])
  });


  // Getters
  get adminFields(): FormArray {
    return this.adminForm.get('formFields') as FormArray;
  }
  get submissionFields(): FormArray {
    return this.submissionForm.get('fields') as FormArray;
  }
  fieldOptions(field: any): FormArray {
    return field.get('options') as FormArray;
  }
  monthlyDonations(field: any): FormArray {
    return field.get('monthlyOptions') as FormArray;
  }
  oneTimeDonations(field: any): FormArray {
    return field.get('oneTimeOptions') as FormArray;
  }
  contactTypeAdminGroup(data: ContactType) {
    return this.formBuilder.group({
      enabled: data.enabled,
      fields: this.formBuilder.group({
        name: this.formBuilder.group(data.fields?.name || {}),
        email: this.formBuilder.group(data.fields?.email || {}),
        streetAddress: this.formBuilder.group(data.fields?.streetAddress || {}),
        city: this.formBuilder.group(data.fields?.city || {}),
        state: this.formBuilder.group(data.fields?.state || {}),
        postalCode: this.formBuilder.group(data.fields?.postalCode || {}),
        country: this.formBuilder.group(data.fields?.country || {}),
        phone: this.formBuilder.group(data.fields?.phone || {}),
        gender: this.formBuilder.group(data.fields?.gender || {}),
        birthday: this.formBuilder.group(data.fields?.birthday || {}),
      })
    });
  }
  contactTypeSubmissionGroup(type: any, key: string, cloneParent: boolean = false): FormGroup | null {
    if (!type.enabled) {
      return null;
    }

    let displayedFields: any = {
      // Extra fields added for submission
      copyPrimary: [cloneParent && key === 'billing'],
      copyBilling: [cloneParent && key === 'shipping'],
      streetAddress2: [null],
      isOrg: [false],
      firstName: [null],
      lastName: [null],
      orgName: [null]
    };

    // parse fields
    for (let field in type.fields) {
      if (type.fields[field].display) {
        if (field === 'email') {
          displayedFields[field] = [null, Validators.email];
        } else if (field === 'phone') {
          displayedFields[field] = this.formBuilder.group({
            number: [''],
            messagingAllowed: [true]
          });
        } else {
          displayedFields[field] = [null];
        }
      }
    }

    let contactTypeGroup = this.formBuilder.group(displayedFields);
    this.updateContactRequirements(contactTypeGroup, type);

    return contactTypeGroup;
  }


  constructor(
    private router: Router,
    private formsApiService: FormsApiService,
    private activeRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private seoService: SEOService,
    private serializer: UrlSerializer,
    private location: Location,
    public loadingService: LoadingService,
    public sanitizer: DomSanitizer
  ) {
    this.idString = activeRoute.snapshot.params['id'];
    if (!this.idString) this.vanityString = activeRoute.snapshot.url[0]?.path;
    this.queryParams = activeRoute.snapshot.queryParams;
    this.adminPreview = this.queryParams.admin_preview === 'true';
  }


  ngOnInit() {
    this.loadingService.isLoading$.subscribe(async loading => {
      setTimeout(() => {
        this.formLoading = loading;
      }, 0);
    });

    this.queryForm();
  }


  async queryForm() {
    const isDefaultQuery = !this.idString && !this.vanityString;
    const isVanityQuery = !this.idString && !!this.vanityString;
    let form;

    this.loadingService.start();

    try {
      if (isDefaultQuery) {
        form = await this.formsApiService.GetDefaultForm({}).toPromise();
      } else if (isVanityQuery) {
        form = await this.formsApiService.GetFormByVanity({
          path: { slug: this.vanityString }
        }).toPromise();
      } else {
        form = await this.formsApiService.GetForm({
          path: { id: +this.idString }
        }).toPromise();
      }
    } catch (error) {
      // this.formNotFound = true;
      this.rerouteToDefault();
      return;
    }

    if (!form) {
      this.rerouteToDefault();
    } else {
      this.formId = (isVanityQuery || isDefaultQuery) ? +form.id : +this.idString;

      // Reroute if outside the date range
      if (
        this.beforeDate(form.startDate) ||
        this.afterDate(form.endDate) ||
        form.active === false
      ) {
        this.rerouteToDefault();
      }

      // Format Data
      this.formatData(form);

      // Replace with Vanity (or ID)
      if (isDefaultQuery) {
        if (!!this.adminForm.value.slug) {
          this.location.replaceState(this.adminForm.value.slug);
        } else {
          this.location.replaceState('/form/' + this.formId);
        }
      }

      // Replace ID with vanity if possible
      if (!isDefaultQuery && !isVanityQuery && this.adminForm.value.slug) {
        const searchParams = new URLSearchParams(this.queryParams).toString();
        this.location.replaceState(this.adminForm.value.slug, searchParams);
      }

      // SEO Updates
      this.seoService.update(
        this.adminForm.value.metaTitle,
        this.adminForm.value.metaDescription,
        this.adminForm.value.metaImage
      );

      this.loadingService.stop();
    }
  }


  async rerouteToDefault() {
    this.loadingService.stop();
    await this.router.navigate(['/']);
    window.location.reload();
  }


  formatData(formQueried: any) {
    this.adminForm.patchValue(formQueried);

    // Process Form Fields
    if (!formQueried?.formFields?.length) {
      this.loadingService.stop();
      return;
    }

    // Loop through form fields
    let processedFields = this.formBuilder.array([]);
    for (let i = 0; i < formQueried.formFields.length; i++) {
      let field = formQueried.formFields[i];

      switch (field.type) {
        case 'plain-text':
          processedFields.push(this.formBuilder.group(field));
          const textField = field as TextField;

          // Validators
          let textValidators = [];
          if (textField.required) {
            textValidators.push(Validators.required);
          }
          if (!!textField.minimumCharacters) {
            textValidators.push(Validators.minLength(textField.minimumCharacters));
          }
          if (!!textField.maximumCharacters) {
            textValidators.push(Validators.maxLength(textField.maximumCharacters));
          }

          this.submissionFields.push(
            this.formBuilder.group({
              id: textField.id,
              value: [null, textValidators]
            })
          );
          break;

        case 'richText':
          processedFields.push(this.formBuilder.group(field));

          this.submissionFields.push(
            this.formBuilder.group({
              id: field.id,
              value: [null, field.required ? Validators.required : null]
            })
          );
          break;

        case 'number':
          processedFields.push(this.formBuilder.group(field));
          const numberField = field as NumberField;

          // Validators
          let numberValidators = [];
          if (numberField.required) {
            numberValidators.push(Validators.required);
          }
          if (!!numberField.minimum) {
            numberValidators.push(Validators.min(numberField.minimum));
          }
          if (!!numberField.maximum) {
            numberValidators.push(Validators.max(numberField.maximum));
          }

          this.submissionFields.push(
            this.formBuilder.group({
              id: field.id,
              value: [null, numberValidators]
            })
          );
          break;

        case 'email':
          processedFields.push(this.formBuilder.group(field));

          this.submissionFields.push(
            this.formBuilder.group({
              id: field.id,
              value: field.required ?
                [null, [Validators.required, Validators.email]] :
                [null, Validators.email]
            })
          );
          break;

        case 'url':
          processedFields.push(this.formBuilder.group(field));

          const urlPattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

          this.submissionFields.push(
            this.formBuilder.group({
              id: field.id,
              value: field.required ?
                [null, [Validators.required, Validators.pattern(urlPattern)]] :
                [null, Validators.pattern(urlPattern)]
            })
          );
          break;

        case 'radio':
          let radioField = field as RadioField;
          let radioOptions = this.formBuilder.array([]);
          let defaultRadioOption = null;

          // Loop through radio options
          if (radioField.options) {
            for (let j = 0; j < radioField.options.length; j++) {
              radioOptions.push(this.formBuilder.group(radioField.options[j]));
              if (radioField.options[j].default) {
                defaultRadioOption = radioField.options[j].value;
              }
            }
          }

          let radioGroup = this.formBuilder.group({
            ...radioField,
            options: radioOptions
          });

          processedFields.push(radioGroup);
          this.submissionFields.push(
            this.formBuilder.group({
              id: field.id,
              value: [defaultRadioOption, field.required ? Validators.required : null]
            })
          );
          break;

        case 'checkbox':
          let checkField = field as CheckboxField;
          let checkOptions = this.formBuilder.array([]);
          let defaultChecks = this.formBuilder.array([]);

          // Loop through options
          if (checkField.options) {
            for (let j = 0; j < checkField.options.length; j++) {
              checkOptions.push(this.formBuilder.group(checkField.options[j]));

              // Save ID if checked by default
              if (checkField.options[j].default) {
                defaultChecks.push(new FormControl(checkField.options[j].id));
              }
            }
          }

          let checkGroup = this.formBuilder.group({
            ...checkField,
            options: checkOptions
          });

          processedFields.push(checkGroup);
          this.submissionFields.push(
            this.formBuilder.group({
              id: field.id,
              value: defaultChecks
            })
          );
          break;

        case 'contact':
          this.contactIndex = i;
          let contactField = field as ContactField;
          this.contactHidden = contactField.hidden;
          let contactGroup = this.formBuilder.group({
            ...contactField,
            primary: this.contactTypeAdminGroup(contactField.primary),
            shipping: this.contactTypeAdminGroup(contactField.shipping),
            billing: this.contactTypeAdminGroup(contactField.billing)
          });

          processedFields.push(contactGroup);
          let submissionObj = this.formBuilder.group({
            primary: this.contactTypeSubmissionGroup(
              contactField.primary,
              'primary'
            ),
            billing: this.contactTypeSubmissionGroup(
              contactField.billing,
              'billing',
              contactField.commonInputsIndicated && contactField.primary.enabled
            ),
            shipping: this.contactTypeSubmissionGroup(
              contactField.shipping,
              'shipping',
              contactField.commonInputsIndicated && contactField.billing.enabled
            ),
          });

          let contactSubmission = this.formBuilder.group({
            id: field.id,
            value: submissionObj,
          });

          this.submissionFields.push(contactSubmission);
          break;

        case 'donation':
          this.donationIndex = i;
          let donationField = field as DonationField;
          this.donationHidden = donationField.hidden;
          let oneTimeOptions = this.formBuilder.array([]);
          let monthlyOptions = this.formBuilder.array([]);

          // Loop through donation options
          if (donationField.oneTimeOptions) {
            for (let j = 0; j < donationField.oneTimeOptions.length; j++) {
              oneTimeOptions.push(this.formBuilder.group(donationField.oneTimeOptions[j]));
            }
          }
          if (donationField.monthlyOptions) {
            for (let j = 0; j < donationField.monthlyOptions.length; j++) {
              monthlyOptions.push(this.formBuilder.group(donationField.monthlyOptions[j]));
            }
          }

          let donationGroup = this.formBuilder.group({
            ...donationField,
            oneTimeOptions: oneTimeOptions,
            monthlyOptions: monthlyOptions
          });

          processedFields.push(donationGroup);

          let donationSubmission = this.formBuilder.group({
            id: field.id,
            type: [donationField.showOneTime ? 1 : 2],
            value: this.formBuilder.group({
              amount: [null],
              repeating: [null],
              paymentType: ['cc'],
              donationDate: [1],
              donateNow: [false],
              account:  [null],
              verificationCode: [null],
              expiryMM: [null],
              expiryYYYY: [null],
              name: [null],
              postalCode: [null],
              routing: [null], // ACH only
              accountType: [null] // ACH only
            })
          }, { validators: this.donationValidation })

          this.submissionFields.push(donationSubmission);
          this.setupCCListener(donationSubmission);
          break;

        default:
          processedFields.push(this.formBuilder.group(field));
          this.submissionFields.push(
            this.formBuilder.group({
              id: field.id,
              value: null,
              contentField: true
            })
          );
          break;
      }
    }

    // Set Fields on Form
    this.adminForm.setControl('formFields', processedFields);
  }


  setupCCListener(donationSubmission: FormGroup) {
    let self = this;

    window.addEventListener('message', function(event) {
      if (event.data && typeof event.data === 'string') {
        const token = JSON.parse(event.data);
        donationSubmission.get('value')?.patchValue({ token: token });
        self.validToken = !!token.message;
        self.tokenQueried = true;
      }
    }, false);
  }


  // Checkbox logic (array of IDs)
  updateCheck(e: Event, fieldIndex: number) {
    const input = < HTMLInputElement > e.target ?? null;
    let idArray = this.submissionFields.at(fieldIndex).get('value') as FormArray;

    if (input.checked) {
      idArray.push(new FormControl(input.value));
    } else {
      for (let i = 0; i < idArray.controls.length; i++) {
        if (idArray.controls[i].value === input.value) {
          idArray.removeAt(i);
          break;
        }
      }
    }
  }


  formatSubmission(): any[] {
    // Don't edit source controls
    let final = this.submissionFields.value;

    // Process Donation Field
    if (this.donationIndex !== undefined && !this.donationHidden) {
      let donationObject = final[this.donationIndex];

      if (donationObject.type === 0) {
        donationObject.value = null;
      } else {
        donationObject.value.repeating = donationObject.type === 1 ? false : true;
        donationObject.value.amount = donationObject.value.amount !== -1 ?
          donationObject.value.amount :
          this.customDonationAmount;
      }
    }

    // Process Contact Field
    if (this.contactIndex !== undefined && !this.contactHidden) {
      let contactObject = final[this.contactIndex];

      if (contactObject.value.billing?.copyPrimary) {
        contactObject.value.billing = contactObject.value.primary;
        delete contactObject.value.billing.copyPrimary;
        delete contactObject.value.billing.copyBilling;
      }

      if (contactObject.value.shipping?.copyBilling) {
        contactObject.value.shipping = contactObject.value.billing;
        delete contactObject.value.shipping.copyPrimary;
        delete contactObject.value.shipping.copyBilling;
      }

      for (let ctype in contactObject.value) {
        if (contactObject.value[ctype]?.isOrg) {
          delete contactObject.value[ctype].firstName;
          delete contactObject.value[ctype].lastName;
          delete contactObject.value[ctype].gender;
          delete contactObject.value[ctype].birthday;
        } else {
          delete contactObject.value[ctype]?.orgName;
        }
      }
    }

    return final;
  }


  submit() {
    if (this.submissionForm.valid) {
      this.submitForm();
    } else {
      this.showValidation = true;

      // Scroll window + container to top
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      document.querySelector('#start')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }


  async submitForm() {
    this.loadingService.start();

    let sub = await this.formsApiService.SaveFormResponse({
      path: {
        id: this.formId,
        revisionId: this.adminForm.value.revisionId
      },
      body: {
        responses: this.formatSubmission()
      }
    }).toPromise();

    if (!sub) return;


    // Format Submission Data
    const submissionDate = moment(sub.createdOn);

    this.submissionData.response = {
      date: submissionDate.format('MMMM Do, YYYY'),
      time: submissionDate.format('h:mm a')
    };

    if (this.donationIndex !== undefined) {
      const donation = sub.responses[this.donationIndex] as any;
      this.submissionData.donation = donation.value
    }

    if (this.contactIndex !== undefined) {
      const contact = sub.responses[this.contactIndex] as any;
      this.submissionData.contact = contact.value;
    }

    console.log(this.submissionData);

    this.submissionComplete = true;
    this.loadingService.stop();
  }


  log() {
    console.log(this.formatSubmission());
  }


  // Utils
  camelCaseLegible(camel: any): string {
    let result = camel.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  ordinal(i: number): string {
    let j = i % 10, k = i % 100;
    if (j === 1 && k !== 11) { return i + "st" }
    if (j === 2 && k !== 12) { return i + "nd" }
    if (j === 3 && k !== 13) { return i + "rd" }
    return i + "th";
  }

  youtubeId(url: string) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length==11) ? match[7] : false;
  }

  afterDate(date: Date | string | undefined): boolean {
    return !!date ? new Date() > new Date(date) : false;
  }

  beforeDate(date: Date | string | undefined): boolean {
    return !!date ? new Date() < new Date(date) : false;
  }


  // const data = {
  //   contact: {
  //     name: 'Joe Bob',
  //     email: 'joebob@test.com',
  //     phone: '123-456-7890',
  //     address1: '123 Fake St.',
  //     address2: 'Apt #123',
  //     city: 'Faketown',
  //     state: 'TX',
  //     zip: '12345'
  //   },
  //   donation: {
  //     amount: '$100.00',
  //     frequency: 'once',
  //     datetime: 'Jan 1, 2025 6:14pm'
  //   },
  //   response: {
  //     datetime: 'Jan 1, 2025 6:14pm'
  //   }
  // };

  hydrateCopy(copy: string): SafeHtml {
    const template = Handlebars.compile(copy);
    return this.sanitizer.bypassSecurityTrustHtml( template(this.submissionData) );
  }
}
