import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { FormComponent } from './components/form/form.component';



const routes: Routes = [
  {
    path: '', // default form
    component: FormComponent
  },
  {
    path: 'form/:id', // form by id
    component: FormComponent
  },
  {
    path: '**', // form by vanity url
    component: FormComponent
  }
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
