import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormComponent } from './components/form/form.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import {EnvironmentService} from "@environments/environment.service";
import {SharedModule} from "@app/shared/shared.module";
import {LoggerModule, NgxLoggerLevel} from "ngx-logger";
import {environment} from "@environments/environment";
import { NgxPayPalModule } from 'ngx-paypal';
import { CommonModule, Location } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { YouTubePlayer } from '@angular/youtube-player';



@NgModule({
  declarations: [
    AppComponent,
    FormComponent
  ],
  imports: [
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.ERROR : NgxLoggerLevel.DEBUG,
    }),
    NgxPayPalModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    CommonModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    YouTubePlayer
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: (envService: EnvironmentService) => () => Promise.all<any>([envService.loadEnvironment()]), deps: [EnvironmentService], multi: true,},],
  bootstrap: [AppComponent]
})
export class AppModule {}
