import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';



@Injectable({
  providedIn: 'root'
}) export class SEOService {


  constructor(
    private title: Title,
    private meta: Meta
  ) {}


  update(title: string, description: string, image: string) {
    this.addBasicTags(window.location.href);
    if (title) this.addTitleTags(title);
    if (description) this.addDescriptionTags(description);
    if (image) this.addImageTags(image);
  }


  addBasicTags(url: string) {
    this.meta.addTags([
      { name: 'og:url', content: url },
      { name: 'twitter:url', content: url },
      { name: 'og:type', content: 'website' },
      { name: 'twitter:card', content: 'summary_large_image' }
    ]);
  }


  addTitleTags(title: string) {
    this.title.setTitle(title);

    this.meta.addTags([
      { name: 'title', content: title },
      { name: 'og:title', content: title },
      { name: 'twitter:title', content: title }
    ]);
  }


  addDescriptionTags(description: string) {
    this.meta.addTags([
      { name: 'description', content: description },
      { name: 'og:description', content: description },
      { name: 'twitter:description', content: description }
    ]);
  }


  addImageTags(image: string) {
    this.meta.addTags([
      { name: 'og:image', content: image },
      { name: 'twitter:image', content: image }
    ]);
  }
}
